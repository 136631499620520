<template>
  <div id="proile-settings">
    <v-card>
      <v-overlay absolute :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card-title class="pa-5 border-bottom">
        <h3 class="title blue-grey--text text--darken-2 font-weight-regular">
          Профиль
        </h3>
        <v-spacer />
        <v-btn small @click="$store.state.settingsModal = false; $emit('close')" fab
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col align-self="center">
            <center>
              <v-avatar :size="256" v-if="form.avatar">
                <img :src="$http.defaults.apiURL + form.avatar" />
              </v-avatar>
              <v-avatar :size="256" v-else>
                <v-icon :size="256">fa-user-circle</v-icon> </v-avatar
              ><br />
              <v-btn @click="toggleShow()" class="mt-3">Изменить фото</v-btn>
              <my-upload
                field="file"
                @crop-success="cropSuccess"
                @crop-upload-success="cropUploadSuccess"
                @crop-upload-fail="cropUploadFail"
                :url="$http.defaults.baseURL + '/profile/update-avatar'"
                v-model="showAvatar"
                langType="ru"
                :width="300"
                :height="300"
                :params="params"
                :headers="headers"
                img-format="png"
              ></my-upload>
            </center>
          </v-col>
          <v-col>
            <v-text-field
              :error-messages="errors.surname ? errors.surname : []"
              :error="errors.surname ? true : false"
              v-model="form.surname"
              label="Фамилия"
              filled
              background-color="transparent"
            ></v-text-field>

            <v-text-field
              :error-messages="errors.name ? errors.name : []"
              :error="errors.name ? true : false"
              v-model="form.name"
              label="Имя"
              filled
              background-color="transparent"
            ></v-text-field>

            <v-text-field
              :error-messages="errors.patronymic ? errors.patronymic : []"
              :error="errors.patronymic ? true : false"
              v-model="form.patronymic"
              label="Отчество"
              filled
              background-color="transparent"
            ></v-text-field>

            <v-text-field
              v-model="form.birthday"
              :error-messages="errors.birthday ? errors.birthday : []"
              :error="errors.birthday ? true : false"
              label="Дата рождения"
              filled
              background-color="transparent"
              type="date"
            ></v-text-field>
          </v-col>
        </v-row>
<v-text-field-simplemask
      v-model="form.phone"
      label="Номер телефона"
      v-bind:properties="{
        prefix: '+7 ',
        suffix: '',
        readonly: false,
        disabled: false,
        outlined: false,
        clearable: true,
        placeholder: '',
      }"
      v-bind:options="{
        inputMask: '(###) ###-##-##',
        outputMask: '##########',
        empty: null,
        applyAfter: false,
        alphanumeric: false,
        lowerCase: false,
      }"
    />
        <v-text-field
          :error-messages="errors.position ? errors.position : []"
          :error="errors.position ? true : false"
          v-model="form.position"
          label="Должность"
          filled
          background-color="transparent"
        ></v-text-field>
        <v-btn
          color="primary"
          :loading="saving"
          @click="onSubmit()"
          class="text-capitalize mr-2 mb-4"
          >Сохранить</v-btn
        >
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import myUpload from "vue-image-crop-upload/upload-2.vue";
export default {
  name: "profile-settings",
  components: {
    myUpload,
  },
  data() {
    return {
      loading: false,
      security: false,
      disabilitySwitch: false,
      form: {},
      errors: [],
      show: true,
      showAvatar: false,
      params: {
        userId: this.userId,
        token: "123456798",
        name: "avatar",
        langType: "en",
      },
      headers: {
        Authorization: "Bearer " + this.$auth.token(),
      },
      saving:false
    };
  },
  props: {
    userId: Number,
  },
  methods: {
    setSecurity() {
      this.security = true;
    },
    onSubmit() {
      this.saving=true
      this.$http
        .put("profile/update?id=" + this.userId, 
           this.form,
        )
        .then((response) => {
          
          if (this.userId == this.$auth.user().user_id) {
            this.$auth.user(response.data);
            
          }
          this.$emit("user-update", response.data);
          this.errors = [];
          this.$store.state.settingsModal = false;
        })
        .catch((e) => {
          if (e.errors) {
            this.errors = e.errors;
          } else {
            alert("Возникла ошибка");
          }
        }).finally(()=>{
          this.saving=false
        })
    },
    fetchData() {
      this.loading = true;
      this.$http
        .get("profile/get", {
          params: {
            id: this.userId,
          },
        })
        .then((response) => {
          // JSON responses are automatically parsed.
          this.form = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    onInput({ number, isValid, country }) {
      if (!isValid) {
        this.errors.phone = "Номер телефона введен неверно";
      } else {
        this.errors.phone = "";
      }
      console.log(number, isValid, country);
    },
    toggleShow() {
      this.showAvatar = !this.showAvatar;
    },
    cropSuccess() {
      console.log("-------- crop success --------");
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {
      this.form.avatar = jsonData;
      this.$emit("user-update", { avatar: jsonData });
      if (this.userId == this.$auth.user().user_id) {
        this.$auth.user().avatar = jsonData;
      }
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },
  },
  watch: {
    userId() {
      this.$set(this.params, "userId", this.userId);
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>